<template>
  <div class="register-page">
    <Backbar
      :title="isInvite ? $t('registerPage.titleEvent') : $t('registerPage.titlePackage')"
      is-clean
      @onBackClick="$router.go(-1)"
    />
    <div class="register-form">
      <ValidationObserver ref="registerForm">
        <div>
          <ValidationProvider
            rules="required"
            name="aura"
            v-slot="{ errors }"
          >
          <Input placeholder="Register Code" v-model="eventMemberCode" disabled/>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-25" >
          <ValidationProvider
            rules="required"
            name="firstName"
            v-slot="{ errors }"
          >
          <Input :placeholder="$t('registerPage.firstName')" v-model="registration.firstName"/>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-25">
          <ValidationProvider
            rules="required"
            name="lastName"
            v-slot="{ errors }"
          >
            <Input :placeholder="$t('registerPage.lastName')" v-model="registration.lastName"/>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="has-margin-top-25">
          <ValidationProvider
            rules="required|min:12"
            name="mobile"
            v-slot="{ errors }"
          >
            <MaskInput
              data-vv-validate-on="change"
              :placeholder="$t('registerPage.phoneNumber')"
              v-model="registration.mobile"
              :mask="'###-###-####'" />
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-text-black87 _fs-14 has-margin-top-25">{{$t('registerPage.gender')}}</div>
        <div v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="gender"
            v-slot="{ errors }"
          >
            <div class="is-flex is-flex-wrap-wrap">
              <RadioButton
                v-model="registration.gender"
                name="gender"
                native-value="Male">
                {{$t('registerPage.male')}}
              </RadioButton>
              <RadioButton
                v-model="registration.gender"
                name="gender"
                native-value="Female">
                {{$t('registerPage.female')}}
              </RadioButton>
              <RadioButton
                v-model="registration.gender"
                name="gender"
                native-value="Prefer not to say">
                {{$t('registerPage.preferNotToSay')}}
              </RadioButton>
            </div>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-text-black87 _fs-14 has-margin-top-25">{{$t('registerPage.dateOfBirth')}}</div>
        <div  v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="birthDate"
            v-slot="{ errors }"
          >
          <DropdownDatePicker v-model="registration.birthDate" required/>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-text-black87 _fs-14 has-margin-top-25">{{$t('registerPage.address')}}</div>
        <div v-if="!isInvite" class="has-margin-top-20">
          <ValidationProvider
            rules="required"
            name="address"
            v-slot="{ errors }"
          >
            <Input :placeholder="$t('registerPage.addressDetail')" v-model="registration.address"/>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="province"
            v-slot="{ errors }"
          >
            <SelectProvince :placeholder="$t('registerPage.province')" v-model="provinceId" @change="onProvinceChange">
              <option v-for="(province, index) in provinces" :key="index" :value="province.id">
                {{ $i18n.locale === 'th' ? province.name_th : province.name_en }}
              </option>
            </SelectProvince>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="district"
            v-slot="{ errors }"
          >
            <SelectProvince :placeholder="$t('registerPage.district')" v-model="districtId" @change="onDistrictChange">
              <option v-for="(district, index) in districts" :key="index" :value="district.id">
                {{ $i18n.locale === 'th' ? district.name_th : district.name_en }}
              </option>
            </SelectProvince>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="subDistrict"
            v-slot="{ errors }"
          >
           <SelectProvince :placeholder="$t('registerPage.subDistrict')" v-model="subDistrictId" @change="onSubDistrictChange">
             <option v-for="(subDistrict, index) in subDistricts" :key="index" :value="subDistrict.id">
              {{ $i18n.locale === 'th' ? subDistrict.name_th : subDistrict.name_en }}
            </option>
           </SelectProvince>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        </div>
        <div v-if="!isInvite" class="has-margin-top-15">
          <ValidationProvider
            rules="required"
            name="postcode"
            v-slot="{ errors }"
          >
            <MaskInput :placeholder="$t('registerPage.postcode')" v-model="registration.postCode" :mask="'#####'"/>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="has-text-black87 _fs-14 has-margin-top-25">{{$t('registerPage.healthCondition')}}</div>
        <div class="has-margin-top-15">
          <AnswerList
            v-model="healthProblems"
          />
        </div>
        <ValidationProvider
          :rules="{'required': isOtherHealthProblem}"
          name="otherHealthProblem"
          v-slot="{ errors }"
        >
          <div class="has-margin-top-5" v-show="isOtherHealthProblem">
            <Input :placeholder="$t('registerPage.other')" v-model="otherHealthProblem"/>
            <span class="error">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div class="has-text-black87 _fs-14 has-margin-top-25">{{$t('registerPage.interestHealthcare')}}</div>
        <div class="has-margin-top-15">
          <AnswerList
            v-model="healthInteresting"
          />
        </div>
        <ValidationProvider
            :rules="{'required': isOtherHealthInteresting}"
            name="otherHealthInteresting"
            v-slot="{ errors }"
          >
          <div class="has-margin-top-5" v-show="isOtherHealthInteresting">
            <Input :placeholder="$t('registerPage.other')" v-model="otherHealthInteresting"/>
            <span class="error">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </ValidationObserver>
      <div class="has-margin-top-50 is-flex align-items-center">
        <div class="is-flex align-items-center justify-center">
          <b-checkbox v-model="isAcceptPolicy" @click.native="() => { isAcceptMarket = !isAcceptPolicy }"/>
          <div class="_fs-14">
            <span>{{$t('registerPage.accept1')}}</span>
            <span class="has-text-secondary" @click="isConditionModalActive = !isConditionModalActive">{{$t('registerPage.accept2')}}</span>
          </div>
        </div>
      </div>
      <div class="has-margin-top-5 is-flex align-items-center">
        <div class="is-flex align-items-center justify-center">
          <b-checkbox v-model="isAcceptMarket" />
          <div class="_fs-14">
            <span>{{$t('registerPage.newsletterAccept')}}</span>
          </div>
        </div>
      </div>
      <div class="has-margin-top-15">
        <Divider/>
        <div class="has-text-centered has-text-black40 _fs-10 has-margin-5">{{$t('registerPage.acceptDetail')}}</div>
        <div class="is-flex align-items-center justify-center has-margin-top-10">
          <RoundButton @click="submit">{{$t('registerPage.register')}}</RoundButton>
        </div>
      </div>
    </div>
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isConditionModalActive"
      :can-cancel="false"
    >
      <ConditionModal/>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
// import moment from 'moment'
import humps from 'humps'
import liff from '@line/liff'

import jsonProvinces from '@/data/provinces.json'
import jsonDistricts from '@/data/districts.json'
import jsonSubDistricts from '@/data/sub_districts.json'

import Backbar from '@/components/Backbar.vue'
import Input from '@/components/base/Input.vue'
import MaskInput from '@/components/base/MaskInput.vue'
import AnswerList from '@/components/AnswerList.vue'
import SelectProvince from '@/components/base/SelectProvince.vue'
import DropdownDatePicker from '@/components/base/DropdownDatePicker.vue'
import RoundButton from '@/components/base/RoundButton.vue'
import Divider from '@/components/base/Divider.vue'
import RadioButton from '@/components/base/RadioButton.vue'

import ConditionModal from '@/components/modal/ConditionModal.vue'

const mockString = Math.random().toString(36).substring(7)
export default {
  components: {
    Input,
    MaskInput,
    Backbar,
    DropdownDatePicker,
    AnswerList,
    SelectProvince,
    RoundButton,
    Divider,
    RadioButton,
    ConditionModal
  },
  data () {
    return {
      isValidForm: false,
      registration: {
        lineAccessToken: '',
        lineUserId: '',
        lineDisplayName: '',
        linePictureUrl: '',
        lineStatusMessage: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        mobile: '',
        address: '',
        province: '',
        district: '',
        subDistrict: '',
        postCode: '',
        gender: '',
        eventMemberCode: ''
      },
      eventMemberCode: '',

      // question
      questionHealthProblemsId: null,
      questionHealthInterestingId: null,

      healthProblems: [],
      healthInteresting: [],

      isOtherHealthProblem: false,
      isOtherHealthInteresting: false,

      otherHealthProblem: '',
      otherHealthInteresting: '',

      // address select
      provinceId: null,
      districtId: null,
      subDistrictId: null,

      errorMessage: '',
      isConditionModalActive: false,
      isAcceptPolicy: false,
      isAcceptMarket: false
    }
  },
  computed: {
    ...mapState('Auth', ['event', 'user', 'code']),
    provinces () {
      return jsonProvinces
    },
    districts () {
      if (this.provinceId === null) {
        return []
      }
      const data = jsonDistricts
        .filter(n => {
          return n.province_id === this.provinceId.toString() && !(n.name_th.includes('*') || n.name_en.includes('*'))
        })
      return data
    },
    subDistricts () {
      if (this.districtId === null) {
        return []
      }
      const data = jsonSubDistricts.filter(n => {
        return n.amphure_id === this.districtId.toString() && !(n.name_th.includes('*') || n.name_en.includes('*'))
      })
      return data
    },
    isInvite () {
      return (this.event && this.event.is_invite) || false
    }
  },
  watch: {
    registration: {
      deep: true,
      handler: function () {
        // this.updateFormValidity()
      }
    },
    healthProblems: function (newValue) {
      this.isOtherHealthProblem = newValue.find((obj) => obj.is_other).is_check
      if (!this.isOtherHealthProblem) {
        this.otherHealthProblem = ''
      }
      // this.updateFormValidity()
    },
    healthInteresting: function (newValue) {
      this.isOtherHealthInteresting = newValue.find((obj) => obj.is_other).is_check
      if (!this.isOtherHealthInteresting) {
        this.otherHealthInteresting = ''
      }
      // this.updateFormValidity()
    },
    otherHealthProblem: function () {
      // this.updateFormValidity()
    },
    otherHealthInteresting: function () {
      // this.updateFormValidity()
    }
  },
  methods: {
    ...mapActions({
      register: 'Auth/register',
      registerPackage: 'Auth/registerPackage',
      login: 'Auth/login',
      updateLineUser: 'Auth/updateLineUser',
      fetchCustomer: 'Auth/fetchCustomer',
      editCustomer: 'Auth/editCustomer',
      fetchHealthQuestion: 'Auth/fetchHealthQuestion',
      fetchInterestQuestion: 'Auth/fetchInterestQuestion',
      postHealthQuestion: 'Auth/postHealthQuestion',
      postInterestQuestion: 'Auth/postInterestQuestion'
    }),
    hideKeyboard () {
      document.activeElement.blur()
    },
    onTagHealthProblemChanged (selectedTag) {
      const tags = this.selectedHealthProblems.filter((t) => t.id === selectedTag.id)
      if (tags.length > 0) {
        this.selectedHealthProblems = this.selectedHealthProblems.filter(t => t.id !== selectedTag.id)
      } else {
        this.selectedHealthProblems.push(selectedTag)
      }
    },
    onTagHealthInterestingChanged (selectedTag) {
      const tags = this.selectedHealthInteresting.filter((t) => t.id === selectedTag.id)
      if (tags.length > 0) {
        this.selectedHealthInteresting = this.selectedHealthInteresting.filter(t => t.id !== selectedTag.id)
      } else {
        this.selectedHealthInteresting.push(selectedTag)
      }
    },
    findProvinceById (provinceId) {
      if (!provinceId) {
        return this.provinces[0]
      }
      const data = jsonProvinces
        .find((p) => {
          return provinceId === p.id
        })
      if (data) {
        return data
      }
      return this.provinces[0]
    },
    findDistrictById (districtId) {
      if (!districtId) {
        return this.districts[0]
      }
      const data = jsonDistricts
        .find((d) => {
          return districtId === d.id
        })
      if (data) {
        return data
      }
      return this.districts[0]
    },
    findSubDistrictById (subDistrictId) {
      if (!subDistrictId) {
        return this.subDistricts[0]
      }
      const data = jsonSubDistricts
        .find((s) => {
          return subDistrictId === s.id
        })
      if (data) {
        return data
      }
      return this.subDistricts[0]
    },
    onProvinceChange () {
      this.districtId = null
      this.subDistrictId = null
      this.registration.district = ''
      this.registration.subDistrict = ''
      this.registration.postCode = ''
      if (this.provinceId !== null) {
        const p = this.findProvinceById(this.provinceId)
        this.registration.province = this.$i18n.locale === 'th' ? p.name_th : p.name_en
      }
      // this.updateFormValidity()
    },
    onDistrictChange () {
      this.subDistrictId = null
      this.registration.postCode = ''
      if (this.districtId !== null) {
        const d = this.findDistrictById(this.districtId)
        this.registration.district = this.$i18n.locale === 'th' ? d.name_th : d.name_en
      }
      // this.updateFormValidity()
    },
    onSubDistrictChange () {
      if (this.subDistrictId !== null) {
        const s = this.findSubDistrictById(this.subDistrictId)
        this.registration.subDistrict = this.$i18n.locale === 'th' ? s.name_th : s.name_en
        this.registration.postCode = s.zip_code
      }
      // this.updateFormValidity()
    },
    async updateFormValidity () {
      const { isValid, errors } = await this.$refs.registerForm.validateWithInfo({
        silent: true
      })
      this.isValidForm = isValid
      console.log('updateFormValidity: ', isValid, errors)
    },
    replaceOtherAnswer (answers, other) {
      return answers.map((obj) => obj.is_other ? { ...obj, other } : { ...obj })
    },
    async submit () {
      this.$wait.start('app loading')
      try {
        const isValid = await this.$refs.registerForm.validate()
        if (!isValid) return
        if (!this.isAcceptPolicy) {
          this.$buefy.toast.open({
            message: this.$t('registerPage.mustAccpet'),
            position: 'is-bottom',
            type: 'is-danger'
          })
          return
        }
        this.hideKeyboard()
        let lineProfile = null
        let lineAccessToken = null
        if (process.env.NODE_ENV === 'production') {
          lineProfile = await liff.getProfile()
          lineAccessToken = await liff.getAccessToken()
        } else {
          lineProfile = {
            userId: mockString
          }
          lineAccessToken = mockString
        }
        this.registration.lineAccessToken = lineAccessToken || ''
        this.registration.lineUserId = (lineProfile && lineProfile.userId) || ''
        this.registration.lineDisplayName = (lineProfile && lineProfile.displayName) || ''
        this.registration.linePictureUrl = (lineProfile && lineProfile.pictureUrl) || ''
        this.registration.lineStatusMessage = (lineProfile && lineProfile.statusMessage) || ''
        this.registration.address_1 = this.registration.address
        this.registration.eventMemberCode = this.eventMemberCode
        console.log('registration', humps.decamelizeKeys(this.registration))
        if (this.isInvite) {
          await this.registerPackage(humps.decamelizeKeys({
            code: this.eventMemberCode,
            lineAccessToken: lineAccessToken || '',
            lineUserId: (lineProfile && lineProfile.userId) || '',
            lineDisplayName: (lineProfile && lineProfile.displayName) || '',
            linePictureUrl: (lineProfile && lineProfile.pictureUrl) || '',
            lineStatusMessage: (lineProfile && lineProfile.statusMessage) || '',
            // TODO
            // birthDate: moment(this.registration.birthDate).format('YYYY-MM-DD'),
            mobile: this.registration.mobile.replace(/-/g, ''),
            isAcceptPolicy: this.isAcceptPolicy,
            isAcceptMarket: this.isAcceptMarket
          }))
        } else {
          await this.register(humps.decamelizeKeys({
            ...this.registration,
            mobile: this.registration.mobile.replace(/-/g, ''),
            isAcceptPolicy: this.isAcceptPolicy,
            isAcceptMarket: this.isAcceptMarket
          }))
        }
        if (process.env.NODE_ENV === 'production') {
          await this.login({
            line_user_id: lineProfile.userId,
            line_access_token: lineAccessToken
          })
        } else {
          await this.login({
            line_user_id: this.registration.lineUserId,
            line_access_token: this.registration.lineAccessToken
          })
        }

        await this.postHealthQuestion({
          ans_choice: JSON.stringify(this.replaceOtherAnswer(this.healthProblems, this.otherHealthProblem)),
          question: this.questionHealthProblemsId
        })
        await this.postInterestQuestion({
          ans_choice: JSON.stringify(this.replaceOtherAnswer(this.healthInteresting, this.otherHealthInteresting)),
          question: this.questionHealthInterestingId
        })
        await this.fetchCustomer()
        this.$router.push({ name: 'Home' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.email) {
            this.errorMessage = this.$t('registerPage.emailExist')
          } else if (error.response.data.mobile) {
            this.errorMessage = this.$t('registerPage.phoneExist')
          } else if (error.response.data.full_name) {
            this.errorMessage = this.$t('registerPage.nameExist')
          } else {
            this.errorMessage = error.response.data
          }
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
        this.$wait.end('app loading')
        window.scrollTo(0, 0)
      }
    }
  },
  created () {
    if (this.event && this.code) {
      this.eventMemberCode = this.code.length === 4 ? this.code : this.event.code

      // this.eventMemberCode = this.event.code
    }
  },
  async mounted () {
    const { choice: problemChoice, id: questionHealthProblemsId } = await this.fetchHealthQuestion()
    this.healthProblems = problemChoice || []
    this.questionHealthProblemsId = questionHealthProblemsId

    const { choice: interestChoice, id: questionHealthInterestingId } = await this.fetchInterestQuestion()
    this.healthInteresting = interestChoice || []
    this.questionHealthInterestingId = questionHealthInterestingId
  }
}
</script>

<style lang="scss" scoped>
.register-form {
  padding: 0px 36px 36px 36px;
}
</style>
